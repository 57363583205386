<template>
  <div>
    <!-- 日期模式 -->
    <div class="couserView" v-if="campDetail.unlockStatus == 1">
      <el-collapse
        class="kcList"
        @change="collapseChange"
        accordion
        v-model="openIndex"
      >
        <div>
          <el-collapse-item
            v-for="(item, index) in campDetail.trainingCampLinks"
            :key="index"
            :name="index"
          >
            <template slot="title">
              <div class="title">
                <div class="shu"></div>
                <div class="title-name">
                  <i
                    class="line"
                    :class="
                      +new Date(item.unlockTime) <= nowTime &&
                      campDetail.isHave == 'true'
                        ? 'title-name'
                        : ''
                    "
                  />
                  <span>{{ item.linkName }}</span>
                </div>
              </div>
              <div class="lockTime">
                {{ switchTime(item.unlockTime) }}
              </div>
              <div class="fenge"></div>
            </template>
            <!-- 章列表 ---->
            <!-- 已解锁样式 -->
            <div
              class="chapterList"
              v-if="
                +new Date(item.unlockTime) <= nowTime &&
                campDetail.isHave == 'true'
              "
            >
              <div
                @click="goLook(val)"
                class="chapterItem"
                v-for="(val, index) in item.trainingCampLinkDetailList"
                :key="index"
              >
                <span class="chapterType haveLock" v-if="val.courseType == 0"
                  >直播</span
                >
                <span class="chapterType haveLock" v-if="val.courseType == 1"
                  >录播</span
                >
                <span class="chapterType haveLock" v-if="val.courseType == 2"
                  >试卷</span
                >
                <span class="chapterType haveLock" v-if="val.courseType == 3"
                  >资料</span
                >
                {{ val.courseName }}
                <div class="progress_box">
                  <div class="progress_bar">
                    <div
                      class="schedule"
                      :style="`width: ${val.rateNum}%`"
                    ></div>
                  </div>
                  <span class="text">{{ val.rateNum }}%</span>
                </div>
              </div>
            </div>
            <!-- 未解锁样式 -->
            <div class="chapterList" v-else>
              <div
                @click="showPoup(val)"
                class="chapterItem"
                v-for="(val, index) in item.trainingCampLinkDetailList"
                :key="index"
              >
                <span class="chapterType" v-if="val.courseType == 0">直播</span>
                <span class="chapterType" v-if="val.courseType == 1">录播</span>
                <span class="chapterType" v-if="val.courseType == 2">试卷</span>
                <span class="chapterType" v-if="val.courseType == 3">资料</span>
                {{ val.courseName }}
                <div class="isLock"></div>
              </div>
            </div>
          </el-collapse-item>
        </div>
      </el-collapse>
      <div class="nothing" v-if="!courseName || courseName.length == 0">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
    </div>
    <!-- 闯关模式 -->
    <div class="couserView" v-else-if="campDetail.unlockStatus == 3">
      <el-collapse
        class="kcList"
        @change="collapseChange"
        accordion
        v-model="openIndex"
      >
        <div>
          <el-collapse-item
            v-for="(item, index) in campDetail.trainingCampLinks"
            :key="index"
            :name="index"
          >
            <template slot="title">
              <div class="title">
                <div class="shu"></div>
                <div class="title-name">
                  <i
                    class="line"
                    :class="
                      item.taskStatus == 1 && campDetail.isHave == 'true'
                        ? 'title-name'
                        : ''
                    "
                  />
                  <span>{{ item.linkName }}</span>
                </div>
              </div>
              <div class="lockTime">
                {{ switchTime(item.unlockTime) }}
              </div>
              <div class="fenge"></div>
            </template>
            <!-- 章列表 ---->
            <!-- 已解锁样式 -->
            <div
              class="chapterList"
              v-if="campDetail.isHave == 'true' && item.taskStatus == 1"
            >
              <div
                @click="goLook(val)"
                class="chapterItem"
                v-for="(val, index) in item.trainingCampLinkDetailList"
                :key="index"
              >
                <span class="chapterType haveLock" v-if="val.courseType == 0"
                  >直播</span
                >
                <span class="chapterType haveLock" v-if="val.courseType == 1"
                  >录播</span
                >
                <span class="chapterType haveLock" v-if="val.courseType == 2"
                  >试卷</span
                >
                <span class="chapterType haveLock" v-if="val.courseType == 3"
                  >资料</span
                >
                {{ val.courseName }}
                <div class="progress_box">
                  <div class="progress_bar">
                    <div
                      class="schedule"
                      :style="`width: ${val.rateNum}%`"
                    ></div>
                  </div>
                  <span class="text">{{ val.rateNum }}%</span>
                </div>
              </div>
            </div>
            <!-- 未解锁样式 -->
            <div class="chapterList" v-else>
              <div
                @click="showPoup1(val)"
                class="chapterItem"
                v-for="(val, index) in item.trainingCampLinkDetailList"
                :key="index"
              >
                <span class="chapterType" v-if="val.courseType == 0">直播</span>
                <span class="chapterType" v-if="val.courseType == 1">录播</span>
                <span class="chapterType" v-if="val.courseType == 2">试卷</span>
                <span class="chapterType" v-if="val.courseType == 3">资料</span>
                {{ val.courseName }}
                <div class="isLock"></div>
              </div>
            </div>
          </el-collapse-item>
        </div>
      </el-collapse>
      <div class="nothing" v-if="!courseName || courseName.length == 0">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
    </div>
    <!-- 无需解锁 -->
    <div class="couserView" v-else-if="campDetail.unlockStatus == 0">
      <el-collapse
        class="kcList"
        @change="collapseChange"
        accordion
        v-model="openIndex"
      >
        <div>
          <el-collapse-item
            v-for="(item, index) in campDetail.trainingCampLinks"
            :key="index"
            :name="index"
          >
            <template slot="title">
              <div class="title">
                <div class="shu"></div>
                <div class="title-name">
                  <i
                    class="line"
                    :class="campDetail.isHave == 'true' ? 'title-name' : ''"
                  />
                  <span>{{ item.linkName }}</span>
                </div>
              </div>
              <div class="lockTime">
                {{ switchTime(item.unlockTime) }}
              </div>
              <div class="fenge"></div>
            </template>
            <!-- 章列表 ---->
            <!-- 已解锁样式 -->
            <div class="chapterList" v-if="campDetail.isHave == 'true'">
              <div
                @click="goLook(val)"
                class="chapterItem"
                v-for="(val, index) in item.trainingCampLinkDetailList"
                :key="index"
              >
                <span class="chapterType haveLock" v-if="val.courseType == 0"
                  >直播</span
                >
                <span class="chapterType haveLock" v-if="val.courseType == 1"
                  >录播</span
                >
                <span class="chapterType haveLock" v-if="val.courseType == 2"
                  >试卷</span
                >
                <span class="chapterType haveLock" v-if="val.courseType == 3"
                  >资料</span
                >
                {{ val.courseName }}
                <div class="progress_box">
                  <div class="progress_bar">
                    <div
                      class="schedule"
                      :style="`width: ${val.rateNum}%`"
                    ></div>
                  </div>
                  <span class="text">{{ val.rateNum }}%</span>
                </div>
              </div>
            </div>
            <!-- 未解锁样式 -->
            <div class="chapterList" v-else>
              <div
                @click="showPoup2(val)"
                class="chapterItem"
                v-for="(val, index) in item.trainingCampLinkDetailList"
                :key="index"
              >
                <span class="chapterType" v-if="val.courseType == 0">直播</span>
                <span class="chapterType" v-if="val.courseType == 1">录播</span>
                <span class="chapterType" v-if="val.courseType == 2">试卷</span>
                <span class="chapterType" v-if="val.courseType == 3">资料</span>
                {{ val.courseName }}
                <div class="isLock"></div>
              </div>
            </div>
          </el-collapse-item>
        </div>
      </el-collapse>
      <div class="nothing" v-if="!courseName || courseName.length == 0">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
    </div>
  </div>
</template>
<script>
import { DataClient } from "@/api/dataClient";
let dataClient = new DataClient();
import { liveEnum } from "@/api/emun";
import Vue from "vue";
import { Know } from "@/api/know";
let know = new Know();
import { getInfo } from "@/api/cookies";
import chater from "@/components/Know/course/chater.vue";
import VideoChater from "@/components/Know/videoChater.vue";
import audioChat from "@/components/Know/course/audioChat.vue";
import faceChat from "@/components/Know/course/faceChat.vue";
import imgChat from "@/components/Know/course/imgChat.vue";
import { tr } from "date-fns/locale";
export default {
  components: { VideoChater, chater, faceChat, audioChat, imgChat },
  data() {
    return {
      liveEnum: liveEnum,
      userInfo: null,
      list: {
        packages: [],
        paperList: [],
      },
      openIndex: 0,
      openChil: 0,

      yearKc: [],
      currentYear: "",
      courseList: [],
      courseName: [],
      nowTime: +new Date(),
      showpopover: true,
    };
  },
  props: ["campDetail"],
  created() {
    this.userInfo = getInfo();
   
  },
  methods: {
    showPoup(val) {
      this.$message("内容未解锁，请到解锁日期后再行观看");
    },
    showPoup1(val) {
      this.$message("内容未解锁，完成上一环节任务后再行观看");
    },
    showPoup2(val) {
      this.$message("内容未解锁，请购买后再行观看");
    },
    collapseChange() {},
    switchTime(time) {
      let month = new Date(time).getMonth() + 1;
      let date = new Date(time).getDate();
      return month + "月" + date + "日";
    },
    goLook(item) {
      if (item.courseType == "0") {
        let userInfo = getInfo();
        if (!userInfo) {
          Vue.prototype.goLoginView(false);
          return;
        }
        if (this.isFreeze == 1) {
          this.$message.error("课程已冻结");
          return;
        }
        if (item.isLive == 0) {
          this.$message.error("课程还未到开播时间");
          return;
        }
        if (item.isLive == 2) {
          this.$message.error("课程直播结束");
          return;
        }
        let sub = {
          id: item.courseId,
          startTime: item.startTime,
          chapterName: item.courseName,
          // classProductLine: this.classProductLine,
        };
        for (let key in sub) {
          if (!sub[key] && sub[key] !== 0) {
            delete sub[key];
          }
        }
        let data = Vue.prototype.export(sub);
        let routeUrl = this.$router.resolve({
          path: `/livebofang?${data}`,
        });
        window.open(routeUrl.href, "_blank");
        // this.$router.push({
        //   path: `/livebofang?${data}`,
        // })
      } else if (item.courseType == "1") {
        let userInfo = getInfo();
        if (!userInfo) {
          Vue.prototype.goLoginView(false);
          return;
        }
        let sub = {
          id: item.courseId,
          uservideoConfig: item.videoConfig,
          courseId: item.lbCourseId,
          classId: item.classId,
          groupId: item.groupId,
          typebs: 2,
          chapterName: item.courseName,
          typekc: this.type,
          // classProductLine: this.classProductLine,
        };
        let data = Vue.prototype.export(sub);
        this.$router.push(`/dianbofang?${data}&openIndex=${0}`);
      } else if (item.courseType == "2") {
        if (!this.userInfo) {
          this.$notify({
            title: "错误",
            message: "未登录，无法做题, 请先去登录",
            type: "error",
            duration: 2000,
          });
          return;
        }
        let sub = {
          id: item.courseId,
          classId: item.id,
          title: item.courseName,
          type: 13,
          IsPurchase: true,
          paperId: item.courseId,
          examType: 4,
        };
        let data = Vue.prototype.export(sub);
        this.$router.push(`/quesTion/examDetails?${data}`);
      } else if (item.courseType == "3") {
        let data = {
          zlId: item.id,
          linkLocation: item.pdfLing,
          type: 1,
        };
        dataClient.downloadRecord(data);
        this.$router.push({
          path: "/VIP/sourcePage",
          query: {
            names: item.pdfLing,
            id: this.coursesId,
            title: this.courseName,
            type: 2,
            IsPurchase: true,
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.couserView {
  background: #fff;
  padding-bottom: 20px;
  .year_wrap {
    min-height: 84px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 32px;
    padding-top: 24px;
    .year_item {
      width: 95px;
      height: 36px;
      border-radius: 8px;
      background-color: #edf0f7;
      color: #565962;
      text-align: center;
      line-height: 36px;
      margin-right: 24px;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .on {
      border: 1px solid #ff5e51;
      background-color: #fff7f6;
      color: #ff5e51;
      font-weight: bold;
    }
  }
}

/deep/.el-collapse {
  border: none !important;
  .redRadio {
    display: none;
  }
  .is-active {
    .redRadio {
      display: inline-block;
    }
  }
  .el-icon-arrow-right {
    transform: rotate(90deg);
  }
  .is-active.el-icon-arrow-right {
    transform: rotate(270deg);
  }
  .el-collapse-item__header {
    height: 58px;
    padding: 19px 32px 19px 0px;
    border: none !important;
    position: relative;
    .lockTime {
      position: absolute;
      top: 6px;
      right: 80px;
      font-size: 16px;
      font-family: Microsoft YaHei-Semilight, Microsoft YaHei;
      font-weight: normal;
      color: #444444;
    }
    .title {
      display: flex;
      height: 20px;
      align-items: center;
      .title-name {
        font: 18px Verdana, Arial, "\5FAE\8F6F\96C5\9ED1";
        /* font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei; */
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        margin-right: 20px;
        .title-name {
          color: #00b578;
        }
        .line {
          width: 2px;
          height: 14px;
          background: #ff5e51;
          border-radius: 1px 1px 1px 1px;
          opacity: 1;
          margin-right: 11px;
        }
        .iconfont {
          color: #333333;
          font-size: 12px;
          margin-right: 9px;
        }
        .tag {
          width: 30px;
          height: 17px;
          margin-left: 8px;
          border-radius: 0 4px 0 4px;
          color: #ffffff;
          font-size: 11px;
          font-weight: 400;
          text-align: center;
          line-height: 17px;
        }
        .must {
          background: linear-gradient(180deg, #458cff 30%, #91c4ff 100%);
        }
        .notRequired {
          background: linear-gradient(180deg, #ff8a00 48%, #facb23 100%);
        }
        .practice {
          background: linear-gradient(180deg, #9832ff 48%, #dcbaff 100%);
        }
      }
      .shu {
        width: 4px;
        height: 20px;
        background: #fff;
        border-radius: 0px 2px 2px 0px;
        opacity: 1;
        margin-right: 29px;
      }
    }
    .fenge {
      // width: 816px;
      height: 1px;
      background: #eeeeee;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: absolute;
      bottom: 0px;
      left: 32px;
      right: 32px;
    }
  }
  .is-active {
    .shu {
      //   background: #ff5e51 !important;
    }
  }
  .el-collapse-item__content {
    border: none !important;
    padding-bottom: 0px;
  }
  .el-collapse-item__wrap {
    border: none !important;
  }
}
/* 按章列表 */
/deep/ .docList {
  padding: 0px 32px;
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__header {
    height: 52px;
    background: #f5f7f9;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    padding: 0px 12px;
    margin-top: 12px;
  }

  .docList-type {
    height: 20px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #ff7b1c;
    padding: 0px 6px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff7b1c;
    line-height: 18px;
    flex-shrink: 0;
    margin-right: 8px;
  }
  .docList-title {
    padding: 20px 0px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    .flex-c,
    .title-text {
      width: calc(100% - 120px);
    }
    .title-text,
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
    }
    .tips {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      margin-right: 17px;
      margin-top: 14px;
      .orange_text {
        color: #ff7b1c;
      }
    }
    .iconfont {
      font-size: 16px;
      width: 25px;
      height: 25px;
      transform: rotate(-90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }
  .open {
    .iconfont {
      transform: rotate(90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }
  .uni-collapse-cell__title-text {
    border: none !important;
  }
  .uni-collapse-cell--open {
    .uni-collapse-cell__title-text {
      border: none !important;
    }
  }
}
/* 录播章节 音频 */
/deep/ .video-chat,
/deep/.audio {
  padding: 0px;
  border-top: 1px solid #eee;
  .el-collapse-item__header {
    padding: 0px 20px !important;
    height: 42px !important;
    line-height: 42px;
    border-left: none !important;
    background: #fff !important;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .el-collapse-item__content {
    .chat-item {
      margin: 0px 35px;
      background: none !important;
    }
  }
}
/deep/ .chater {
  // border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  .chat-item {
    padding: 0px 28px;
  }
}
/* 试卷bao列表 */
.paperList {
  width: 100%;
  padding: 0px 32px 20px;
  .item-box {
    .item {
      width: 100%;
      display: flex;
      height: 264px;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      padding: 32px;
      margin-bottom: 24px;
      position: relative;
      .item-image {
        width: 200px;
        height: 200px;
        flex-shrink: 0;
        background: #f7f8f9;
        margin-right: 24px;
      }
      .item-content {
        width: 100%;
        .item-title {
          height: 88px;
          font-size: 28px;
          font-family: PingFang SC-Medium;
          font-weight: 500;
          color: #373a42;
          line-height: 44px;
        }
        .item-num {
          padding: 0px 12px;
          display: inline-block;
          height: 34px;
          background: #fff6ef;
          border-radius: 8px 8px 8px 8px;
          font-size: 22px;
          font-family: PingFang SC-Regular;
          font-weight: 400;
          color: #ff852d;
          line-height: 34px;
          margin-top: 8px;
        }

        .item-tips {
          margin-top: 34px;
          font-size: 24px;
          font-family: PingFang SC-Medium;
          line-height: 36px;
          font-weight: 500;
          color: #ff5d51;
          flex-shrink: 0;
        }
      }
    }
  }
}

/* 章节列表 */
.chat-list {
  padding: 0px;
  .chat-item {
    width: 100%;
    padding: 32px 0px;

    .item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 300px);

      .title {
        font-size: 28px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        color: #373a42;
        line-height: 28px;
      }
      .time {
        font-size: 24px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #9fa4ad;
        line-height: 24px;
        margin-top: 16px;
      }
      /* 直播 */
      .liveIng {
        font-size: 24px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #ff5d51;
        line-height: 24px;
        .live {
          color: #ff5d51;
          font-size: 24px;
          margin-right: 8px;
        }
      }
      /* 回放 */
      .video {
        color: #6c7079;
        font-size: 40px;
      }
    }
    /* 未开始  回放生成中 */
    .noLive {
      color: #9fa4ad;
      .title,
      .time,
      .video {
        color: #9fa4ad !important;
      }
    }
    .but-list {
      flex-shrink: 0;
      display: flex;
      width: 240px;
      justify-content: flex-end;
      align-items: center;
      .keep-learn {
        margin-right: 12px;
        flex-shrink: 0;
        width: 72px;
        height: 24px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #ff5e51;
        padding: 0px;
        text-align: center;
        line-height: 24px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    /* .but-list {
      display: flex;
      margin-top: 48px;
      justify-content: flex-end;
      flex-shrink: 0;
      .item-but {
        margin-left: 24px;
        padding: 0px 24px;
        height: 48px;
        border-radius: 8px 8px 8px 8px;

        border: 2px solid #ff5d51;
        font-size: 24px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #ff5d51;
        line-height: 48px;
        text-align: center;
        &:last-child {
          margin-right: 0px;
        }
      }
    } */
  }
}
.chapterList {
  padding: 0 45px;
  .chapterItem {
    position: relative;
    height: 55px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    font-size: 16px;
    font-family: Microsoft YaHei-Semilight, Microsoft YaHei;
    font-weight: normal;
    color: #444444;
    .isLock {
      position: absolute;
      width: 15px;
      height: 17px;
      top: 17px;
      right: 6px;
      background: url("~@/style/image/camp/locks.png") no-repeat top left / 100%
        100%;
    }
    .chapterType {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 20px;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      border: 1px solid #ff9a02;
      font-size: 12px;
      font-weight: normal;
      color: #ff9900;
      margin-right: 13px;
    }
    .haveLock {
      color: #00b578;
      border: 1px solid #00b578;
    }
  }
}
// 进度条
.progress_box {
  position: absolute;
  right: 0;
  top: 16px;
  display: flex;
  align-items: center;
  .progress_bar {
    width: 105px;
    height: 6px;
    background-color: #e5e5e5;
    border-radius: 10px;
    .schedule {
      background: linear-gradient(90deg, #00b578 0%, #00b578 100%);
      height: 8px;
      width: 0;
      border-radius: 10px;
    }
  }

  .text {
    font-size: 12px;
    color: #00b578;
    margin-left: 8px;
  }
}
</style>

