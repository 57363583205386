<template>
  <div class="warpConss chat-list">
    <div v-for="(item, index) in CourseList" :key="index" class="chat-item">
      <div class="item-title" :class="item.isLive == 0 || item.isLive == 2 ? 'noLive' : ''">
        <!-- @click.stop="gkcb(item)" -->
        <!-- @click.stop="gkcb(item)" -->
        <div class="tt-box">
          <div class="flex-c ellipsis">
            <div class="title ellipsis"><i class="iconfont icon-a-Union21" />{{ item.name }}</div>
          </div>
          <div class="time ellipsis">
            <!-- {{ item.attendTime | format("yyyy.MM.dd HH:mm") }}-{{ item.finishTime | format("HH:mm") }} -->
          </div>
          <!-- <div class="attendPlace ellipsis">上课地点：{{ attendPlace }}</div> -->
        </div>
      </div>
      <div v-if="(item.docList && item.docList.length > 0) || (item.contactList && item.contactList.length > 0)"
        class="but-list">
        <div>
          <div v-if="ifClass ? dealDocType(item, 2) : item.isHasContantJY == 'true'" class="keep-learn"
            @click.stop="goData(item, 4)">
            随堂讲义
          </div>
          <div v-if="ifClass ? item.contactList.length > 0 : item.isHasContant == 'true'" class="keep-learn"
            @click.stop="cgeckTask(item)">
            随堂测试
          </div>
          <div v-if="ifClass ? dealDocType(item, 1) : item.isHasDoc == 'true'" class="keep-learn"
            @click.stop="goData(item, 2)">
            随堂资料
          </div>
        </div>
      </div>
    </div>
    <div v-if="dialogVisible" class="warpCpons" @touchmove.stop.prevent="stop()">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png">
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click="tChuang">
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getLbClassCourseById } from '@/api/home'
import { IsLive } from '@/api/emun'
import { getInfo, getToken } from '@/api/cookies'
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
export default {
  props: {
    id: {},
    list: {},
    classId: {},
    purchase: {
      type: Boolean
    },
    attendPlace: {
      type: String,
      default: ''
    },
    ifClass: {
      type: Boolean
    },
    isFreeze: null
  },
  data () {
    return {
      userInfo: null,
      IsLive: IsLive,
      CourseList: [],
      dialogVisible: false,
      IsPurchase: false
    }
  },
  watch: {
    list () {
      this.IsPurchase = this.purchase
      this.CourseList = this.list
    }
  },
  created () {
    this.userInfo = getInfo()
    this.IsPurchase = this.purchase
    this.CourseList = this.list
  },
  methods: {
    /* 判断是否有讲义 */
    dealDocType (item, type) {
      let temp = false
      if (item.docList && item.docList.length > 0) {
        item.docList.forEach((element) => {
          if (element.docType == type) {
            temp = true
          }
        })
      }
      return temp
    },
    tChuang () {
      this.dialogVisible = false
    },
    stop () {
      return
    },
    // 观看重播按钮  切换课程章节播放
    gkcb (v) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase) {
        // this.$message.error("尚未购买，请先去购买课程！");
        this.dialogVisible = true
        return
      }
      if (this.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }
      const sub = { id: v.id, classId: this.classId, courseId: this.id }
      const data = Vue.prototype.export(sub)
      this.$router.push({
        path: `/faceCouser/faceChatView?${data}`
      })
    },

    // 课堂作业
    cgeckTask (item) {
      let sId = item.sId
      let title = item.title
      if (this.ifClass) {
        title = item.contactList[0].sjName
        sId = item.contactList[0].cContactid
      }
      answerClient.selectPaperSubjectList(sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            title,
            sId,
            2,
            10,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.id,
            item.id,
            this.classId
          )
        }
      })
    },
    /* 随堂资料 */
    goData (item, type) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true
        return
      }
      window.open(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`, '_blank')

      // this.$router.push(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`);
    }
    // 随堂资料
    /*  goData(item) {
      if (!this.IsPurchase) {
        this.dialogVisible = true;
        return;
      }
      // data/daclickackageDetail?packageId=579
      this.$router.replace(`/ketangziliao?id=${item.id}&typebs=1`);
    }, */
  }
}
</script>
<style lang="less" scoped>
// 缩宽
.warpConss {
  width: 100%;
  height: auto;
  .warpCpons {
    // position: absolute;
    position: fixed;
    z-index: 2000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.6);
    div {
      width: 610px;
      height: auto;
      // margin-top: 400px;
      display: flex;
      z-index: 20;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      background: rgba(255, 255, 255, 0);
      .imga {
        width: 100%;
        height: 420px;
      }
      .imgb {
        width: 36px;
        height: 36px;
        margin-top: 30px;
        cursor: pointer;
      }
      span {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #6c90fe;
        margin-top: -160px;
      }
    }
  }
  // 单个内容展开项盒子
  .warpConskexqq {
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 30px 0px;
    .warpConskexq {
      width: 100%;
      height: auto;
      padding: 1px;
      // 某个展开项
      p {
        width: auto;
        height: 13px;
        font-size: 15px;
        margin: 20px 0px 30px 10px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27323f;
      }
      .warpConskexqs {
        width: 1175px;
        height: 50px;
        margin: 10px auto;
        background: #f7f7f7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // 左侧
        .warpConskexqsL {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          div {
            width: 4px;
            height: 50px;
            background: #dddddd;
            margin-right: 27px;
          }
          .on {
            background: rgb(107, 187, 251);
          }
          span {
            width: auto;
            height: auto;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
          }
        }
        // 右侧
        .warpConskexqsR {
          width: 320px;
          height: 32px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          div {
            width: 90px;
            height: 32px;
            margin-right: 10px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.chat-list {
  padding: 0px 32px;
  .chat-item {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    .item-title {
      width: calc(100% - 300px);
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      .tt-box {
        margin-top: 20px;
        width: 100%;
      }
      .lilve-title {
        color: #ff5e51 !important;
      }
      .title {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
      }
      .iconfont {
        font-size: 14px;
        margin-right: 12px;
      }
      .time {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        margin-top: 12px;
      }
      /* 直播 */
      .liveIng {
        padding: 0px 4px;
        height: 20px;
        background: #ff5e51;
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        margin-left: 8px;
        .live {
          color: #fff;
          font-size: 12px;
          margin-right: 4px;
        }
      }
      /* 回放 */
      .video {
        color: #6c7079;
        font-size: 40px;
      }
    }
    /* 未开始  回放生成中 */
    .noLive {
      color: #9fa4ad;
      .title,
      .time,
      .video {
        color: #9fa4ad !important;
      }
    }
    .but-list {
      flex-shrink: 0;
      display: flex;
      width: 300px;
      justify-content: flex-end;
      .keep-learn {
        margin-right: 10px;
        flex-shrink: 0;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
