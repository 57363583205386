import { render, staticRenderFns } from "./faceChat.vue?vue&type=template&id=4efcc14a&scoped=true&"
import script from "./faceChat.vue?vue&type=script&lang=js&"
export * from "./faceChat.vue?vue&type=script&lang=js&"
import style0 from "./faceChat.vue?vue&type=style&index=0&id=4efcc14a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4efcc14a",
  null
  
)

export default component.exports