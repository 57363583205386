import request from "@/common/utils/request.js";
//web-训练营首页列表
export const getCampList = (params)=> request({
    url:'kecheng/trainingCampWeb/getCampList',
    method:'GET',
    params
})
//web-推荐营期列表
export const getProductRecommendCamp = (params)=> request({
    url:'kecheng/trainingCampWeb/getProductRecommendCamp',
    method:'GET',
    params
})
// 训练营详情
export const getCampDetail = (params)=> request({
    url:'kecheng/trainingCampWeb/getCampDetail',
    method:'GET',
    params
})
// 报名
export const mAddOrder = (params)=> request({
    url:'/stu/order/mAddOrder',
    method: "POST",
    data:params
})
// 训练营报名
export const addOrders = function (params) {
    return request({
      url: "/stu/order/pcAddOrder",
      method: "POST",
      data:params
    })}