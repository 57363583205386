<template>
  <!-- 右侧推荐 -->
  <div class="appleRight">
    <!-- 块一 -->
    <div class="appleRightTop">
      <div class="love-two-title">
        <div class="text">
          <slot>猜你喜欢</slot>
          <img class="icon" src="@/assets/img/Home/tips.png">
        </div>
      </div>
      <!-- <div class="appleRightTopa"><slot>猜你喜欢</slot></div> -->
      <!-- 内容1 -->
      <div v-for="(item, index) in list" :key="index" class="appleRightTopb" @click="detail(item)">
        <div class="appleRightTopbs pointer">
          <img class="img" :src="item.campCover">
          <div class="appleRightTopbsR">
            <div class="title twoEllipsis">
              {{ item.campName }}
            </div>
            <div class="campInfo">
              共{{ item.linkNum }}节/{{ item.taskNum }}个任务
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 块二 -->
    <div class="appleRightCen" />
    <!-- 块三 -->
    <!--    <div class="appleRightBtm">
      <div class="appleRightBtma">聚学云网校</div>
      <div class="appleRightBtmb">
        <div class="appleRightBtmbs">
          <img src="@/assets/img/kecheng/gzh.png" />
          <p>聚学云网校公众号</p>
        </div>
        <div class="appleRightBtmbs">
          <img src="@/assets/img/kecheng/xcx.png"  />
          <p>聚学云网校小程序</p>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { getProductRecommendCamp } from '@/api/camp'
import { getDomain, getInfo } from '@/api/cookies'
export default {
  props: ['type', 'id', 'size', 'pageNum', 'ifteacher'],
  data() {
    return {
      userInfo: getInfo(),
      list: []
    }
  },
  created() {
    /** 训练营推荐*/
    this.getRecommendList()
  },

  methods: {
    getRecommendList() {
      getProductRecommendCamp({
        pageSize: 10,
        pageNum: 1,
        tenantId: this.userInfo.tenantId
      }).then((res) => {
        this.list = res.rows

      })
    },
    /*   if (item.types == 1) {
        this.$router.push(`/zhiboclassxq?id=${item.id}`);
      } else if (item.types == 2) {
        this.$router.push(`/videoxq?id=${item.id}`);
      } else if (item.types == 3) {
      id=${item.id}`);
      } else if (item.types == 4) {
        this.$router.push(`/imgCouser/index?id=${item.id}`);
      } else if (item.types == 5) {
        this.$router.push(`/faceCouser/index?id=${item.id}`);
      } */
    // 跳转
    detail(row) {
      this.$router.push(`/campDetail?id=${row.id}`)
    }
  }
}
</script>
<style lang="less" scoped>
.appleRight {
  width: 320px;
  height: fit-content;
  background: #ffffff;
  border-radius: 6px;

  .appleRightTop {
    width: 280px;
    height: auto;
    margin: 20px auto;

    .appleRightTopb {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .appleRightTopbs {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;

        .img {
          margin-right: 12px;
          width: 120px;
          height: 67.5px;
          background: #c4c4c4;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          flex-shrink: 0;
        }

        .appleRightTopbsR {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 145px;
          height: 70px;

          .campInfo {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }

          .title {
            height: 44px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }

          .clicks {
            font-size: 14px;
            color: #999;
          }

          .price {
            font-size: 18px;
            color: #f15817;
          }
        }
      }
    }
  }

  .appleRightCen {
    width: 100%;
    height: 20px;
    background: #f5f6fa;
  }

  .appleRightBtm {
    width: 280px;
    height: 200px;
    margin: auto;

    .appleRightBtma {
      margin-top: 20px;
      width: 100%;
      height: 20px;
      border-left: 3px solid #009aff;
      font-size: 18px;
      color: #333;
      margin-bottom: 2px;
      padding-left: 8px;
      line-height: 18px;
    }

    .appleRightBtmb {
      width: 100%;
      height: 144px;
      display: flex;
      margin-top: 30px;

      .appleRightBtmbs {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 100px;
          height: 100px;
        }

        p {
          width: 100%;
          height: 34px;
          font-size: 14px;
          color: #626262;
          line-height: 34px;
          text-align: center;
        }
      }
    }
  }
}

/* 列表-讲师 */
.detail-ter {
  .ter-list {
    display: flex;
    align-items: center;

    .terbox {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
      background: #a8abbe;
      border: none;
      overflow: hidden;
    }

    .ter-img {
      width: 24px;
    }

    .ter {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
    }
  }
}</style>
